import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import Header from './../Layout/Header1';
import Footer from './../Layout/Footer1';

import bgimg from './../../images/background/bg2.jpg'; 
import Axios from 'axios';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class Contact extends Component {

    constructor(props) {
        super(props);

        this.state = {
            redirect: false,
            f_name: "",
            email: "",
            phone: "",
            message: "",
            errors: {
                f_name: "",
                email: "",
                phone: "",
                message: "",
            },
            loading: false,
        };
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        const errors = this.state.errors;

        switch (name) {
            case "f_name":
                errors.f_name = value.trim() ? "" : "This field is required.";
                break;
            
            case "email":
                    errors.email = value.trim() ? "" : "This field is required.";
                    break;

            case "phone":
                    errors.phone = value.trim() ? "" : "This field is required.";
                    break;

            case "message":
                    errors.message = value.trim() ? "" : "This field is required.";
                    break;
        
            default:
                break;
        }

        this.setState({ errors, [name]: value});

    };

    sendMail = (event) => {
        event.preventDefault();

            this.setState({ loading: true });

            const { f_name, email, phone, message } = this.state;

            if(f_name !== "" && email !== "" && phone !== "" && message !== "") {
                const postData = new FormData();
                postData.append('f_name', f_name);
                postData.append('email', email);
                postData.append('phone', phone);
                postData.append('message', message);
                
                Axios("https://fletofix.com/mail.php", {
                    method: "POST",
                    data: postData,
                    headers: { "Content-Type": "multipart/form-data" },
                })
                    .then((res) => {
                        alert("Your message has been successfully sent. We will contact you very soon!");

                        this.props.onSearchTermChange(f_name);
                        
                        
                        this.setState({f_name: "", email: "", phone: "", message: ""});
                        console.log(res);
                          

                    
                    })
                    .catch((err) => {
                        console.log("in error");
                        const f_name = "";
                        const email = "";
                        const phone = "";
                        const message = "";
                      
                        this.setState({f_name, email, phone, message});
                        
                    });
            } else {
                alert("All fields are required");
            }        
    };
    

    render() {
        return (
            <>
                <Header />
                <div className="full-section">
                    {/* <!-- inner page banner --> */}
                    <div className="dlab-bnr-inr overlay-primary-dark contact-page" style={{ backgroundImage: "url(" + bgimg + ")" }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4 col-xs-4">
                                    <div className="row text-white">
                                        <div className="col-lg-12 col-md-6 m-b30" >
                                            <div className="icon-bx-wraper bx-style-1 p-a20 radius-sm">
                                                <div className="icon-content">
                                                    <h5 className="dlab-tilte">
                                                        <span className="icon-sm text-primary text-left"><i className="ti-location-pin"></i></span>
                                                        Address
													</h5>
                                                    <p>Baridhara K Block, Dhaka 1212, Dhaka Division, Bangladesh </p>
                                                    <h6 className="m-b15 font-weight-400"><i className="ti-alarm-clock"></i> Open Hours</h6>
                                                    <p className="m-b0">24/7</p>
                                                    {/* <p>Sunday - Close</p> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-6 m-b30 ">
                                            <div className="icon-bx-wraper bx-style-1 p-a20 radius-sm">
                                                <div className="icon-content">
                                                    <h5 className="dlab-tilte">
                                                        <span className="icon-sm text-primary text-left"><i className="ti-email"></i></span>
                                                        E-mail
													</h5>
                                                    <p className="m-b0">info@fletofix.com</p>
                                                    {/* <p className="m-b0">sales@fletofix.com</p> */}
                                                    {/* <p className="m-b0">contact@example.com</p> */}
                                                    <p>fletofix@gmail.com</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-6 m-b30 ">
                                            <div className="icon-bx-wraper bx-style-1 p-a20 radius-sm">
                                                <div className="icon-content">
                                                    <h5 className="dlab-tilte">
                                                        <span className="icon-sm text-primary text-left"><i className="ti-mobile"></i></span>
                                                        Phone Numbers
														</h5>
                                                    <p>+88 01821-376482</p>
                                                    <p>+88 01553-672389</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-8 col-xs-8">
                                    <form className="inquiry-form dzForm"  onSubmit={this.sendMail}>
                                        <div className="dzFormMsg"></div>
                                        <h3 className="box-title m-t0 m-b10">Let's Convert Your Idea into Reality <span className="bg-primary"></span></h3>
                                        <p>Fletofix is digital marketing agency, that deliver
digital marketing strategies to companies worldwide. Contact
us today to find out how we can help you.</p>
                                       
                                       
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <span className="input-group-addon"><i className="ti-user text-primary"></i></span>
                                                        <input name="f_name" onChange={this.handleChange} value={this.state.f_name} type="text" required className="form-control" placeholder="Full Name" />
                                                    </div>
                                                    
                                                        <div className="text-danger">{this.state.errors.f_name}</div>
                                                </div>
                                            </div>
                                            {/* <div className="col-lg-6">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <span className="input-group-addon"><i className="ti-user text-primary"></i></span>
                                                        <input name="l_name" onChange={this.handleChange} type="text" required className="form-control" placeholder="Last Name" />
                                                    </div>
                                                </div>
                                            </div> */}

                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <span className="input-group-addon"><i className="ti-mobile text-primary"></i></span>
                                                        <input name="phone" onChange={this.handleChange} value={this.state.phone} type="text" required className="form-control" placeholder="Phone" />
                                                    </div>
                                                    <div className="text-danger">{this.state.errors.phone}</div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <span className="input-group-addon"><i className="ti-email text-primary"></i></span>
                                                        <input name="email" type="email" onChange={this.handleChange} value={this.state.email} className="form-control" required placeholder="Your Email Id" />
                                                    </div>
                                                    <div className="text-danger">{this.state.errors.email}</div>
                                                </div>
                                            </div>


                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <span className="input-group-addon"><i className="ti-agenda text-primary"></i></span>
                                                        <textarea name="message" onChange={this.handleChange} value={this.state.message} rows="4" className="form-control" required placeholder="Tell us about your project or idea"></textarea>
                                                    </div>
                                                    <div className="text-danger">{this.state.errors.message}</div>
                                                </div>
                                            </div>
                                            
                                            <div className="col-lg-12">
                                                <button type="submit"   className="button-lg site-button"> <span>Get A Free Quote!</span> </button>
                                            </div>
                                        </div>


                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- inner page banner END --> */}
                </div>

                <Footer />
            </>
        )
    }
}
export default Contact;