import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from './../../Layout/Header1';
import Footer from './../../Layout/Footer1';
import PageTitle from './../../Layout/PageTitle';
import SectionCounter from './../../Element/SectionCounter';
import TestimonialCarousel from './../../Element/TestimonialCarousel';
import FormStyle from './../../Element/FormStyle';

//Images
import bnr5 from './../../../images/banner/bnr5.jpg';
import bgmap from './../../../images/background/bg-map.jpg';
import bg1 from './../../../images/background/bg1.jpg';

const iconBox = [

	{icon: <i className="flaticon-devices" />, title:'Web Design Solutions', des:'Really Compelling!!! This is what our web Design and Development team is striving to achieve in their reviews. We are artistic in our designs and modern in our tools hence different from the crowed in your town.' },
    {icon: <i className="flaticon-pen" />, title:'UIUX & Graphic Design Solutions', des:'A great tech based service goes a long way with the right user experience design. Our experienced design team can pull off outstanding UX designs through a complete pipeline of user behavior research, wireframe design, UI design and testing.' },
    {icon: <i className="flaticon-diamond" />, title:'Creative Strategy', des:'A comparative research and a competitive analysis can give a new life to your business. It takes perspirations to ponder upon documents and dimensions to create target oriented research and analysis. Through this, We help you to take a Smart Start.' },
    {icon: <i className="flaticon-marketing" />, title:'Branding', des:'You dream for your brand and we turn it to reality. Through a grasping strategy, we design and develop a vision that can create a WOW impact on your target audience.' },
    {icon: <i className="flaticon-star" />, title:'Photography and Videography', des:'  With many big name companies that have already trusted and used our company are now seeing how we stand out from other commercial photography and videography companies.  We want to include a personal touch and communicate with you directly as a client, and not just as another project.' },  
    {icon: <i className="flaticon-bar-chart" />, title:'Online Marketing Support', des:'Trigger targeted campaigns on search engines and social media for mature leads. Keep your website up to date with our maintenance plans.' },

	{icon: <i className="flaticon-computer" />, title:'Custom Software Development', des:'We offer our customers a wealth of software expertise. We create diverse and complex software solutions for any business need. We will transform your ideas into reality from scratch, designed especially for your business. Our team works autonomously but communicates and reports status regularly.'},

	{icon: <i className="flaticon-smartphone" />, title:'Mobile Applications',des:'We offer full-cycle mobile app development services. This includes design, expert business analysis, and development of a proper mobile application. We try our level best to integrate a new product into your infrastructure and thus providing some further optimization or scale-up on demand.' },
    {icon: <i className="flaticon-internet" />, title:'E-Commerce Solutions', des:'The design and functionality of your eCommerce website design could make or break conversions and affect your bottom line. An eCommerce site has several layers of functionality that aren’t on standard websites, including a web server to host a storefront, payment processing, an inventory database and a complicated dispatch system used to send products.' },
    
    {icon: <i className="flaticon-mind" />, title:'Social Media Marketing', des:'We’re here to choose the perfect social media management strategy for you business that will meet and exceed your needs and requirements. Engaging with your audience on social media.' },
    {icon: <i className="flaticon-team" />, title:'Social Media Management', des:'Our social media team is highly skilled in social media strategy and content creation. We start by performing thorough research on your industry and applying the latest social media strategies to your social accounts. This targeted content inspires your followers to engage with and support your business'},
    {icon: <i className="flaticon-file" />, title:'Content Writing', des:'Effective websites require quality content to best represent their brand or services. If you want your website to achieve your sales goals, it must contain search engine optimized, descriptive, original, understandable content' },
    {icon: <i className="flaticon-play-button" />, title:'Motion Graphic & Video Editing', des:'We have another service, doing all kinds of video editing with your raw footage. If you are looking for a trustworthy video editing company to have your videos professionally and quickly edited, we will gladly fulfill the task.'},
    {icon: <i className="flaticon-internet" />, title:'F-Commerce Solutions', des:'F-Commerce is one of the newest form of online business versions which got popular among the young entrepreneurs of many countries because of its wide marketing reach due to better targeting, greater interaction and higher return on investment.'},

    
	{icon: <i className="flaticon-file" />, title:'Pre Customized Solutions', des:'Fletofix is a full-service digital marketing agency and we have great experience driving most impressive results for multiple clients in a number of industries. If you want to enhance your online access and generate more revenue, you need a trusted digital marketing partner – a partner like Fletofix. '},
    
	{icon: <i className="flaticon-devices" />, title:'Project Maintenance', des:'We’ll identify and fix software design, logical and coding errors, bugs and defects to increase system stability, consistency of software operation and satisfaction of your customers.'},
	{icon: <i className="flaticon-file" />, title:'Data Entry', des:'First, we are TRUSTED by SMART companies of different industries in need of a top-notch data entry outsourcing company offering data entry services, data processing services including data enrichment, data categorization and validation, data annotation for AI and content moderation in 20+ languages.' },
    
];

class Service extends Component {


    render() {
        return (
            <>
                <Header />

                <div className="page-content bg-white">
                    {/* <!-- inner page banner --> */}
                    <div className="dlab-bnr-inr overlay-primary" style={{ backgroundImage: "url(" + bnr5 + ")" }}>
						<PageTitle motherMenu='Our Services'  activeMenu='Our Services' />
                    </div>
                    {/* <!-- inner page banner END --> */}
                    <div className="content-block">
                        {/* <!-- About Us --> */}
                        <div className="section-full content-inner">
                            <div className="container">
                                <div className="section-head text-black text-center">
                                    <h4 className="text-gray-dark m-b10">Our Services</h4>
                                    <h2 className="box-title m-tb0">WE DESIGN DIGITAL PRODUCTS THAT HELP GROW BUSINESSES.<span className="bg-primary"></span></h2>
                                    <p>As a professional team of multidisciplinary specialists, with creative experience and certified in Digital marketing courses (Facebook Marketing, Google Advert) runs deep in website design, optimization social networks & interactive media development, brand development, website development, web designing & positioning; social media marketing, online marketing, website traffic & advanced search engine marketing.</p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row ">
									{iconBox.map((data, index )=>(
										<div className="col-md-4 col-sm-6 m-b30 "  key={index}>
											<div className="icon-bx-wraper expertise  bx-style-1 p-a30 center">
												<div className="icon-lg m-b20"> <Link to={"#"} className="icon-cell">{data.icon}</Link> </div>
												<div className="icon-content">
													<h5 className="dlab-tilte text-uppercase"><Link to={"#"}>{data.title}</Link></h5>
													{/* <p>Lo rem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy ..</p> */}
                                                    <p>{data.des ? data.des : "heelllo"}</p>
												</div>
											</div>
										</div>
									))}	
                                </div>
                            </div>
                        </div>
                        {/* <!-- Our Services --> */}
                        {/* <!-- Why Chose Us --> */}
							<SectionCounter />
                        {/* <!-- Why Chose Us End --> */}
                        <div className="section-full content-inner-1">
                            <div className="container">
                                <div className="row">
                                    {/* <div className="col-lg-12">
                                        <div className="section-head text-center">
                                            <h2 className="box-title m-tb0">Our Pricing<span className="bg-primary"></span></h2>
                                            <p> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the </p>
                                        </div>
                                        <!-- Pricing table-1 Columns 3 with gap -->
                                        <div className="section-content box-sort-in button-example">
                                            <div className="pricingtable-row">
                                                <div className="row max-w1000 m-auto">
                                                    <div className="col-sm-12 col-md-4 col-lg-4 p-lr0">
                                                        <div className="pricingtable-wrapper style1">
                                                            <div className="pricingtable-inner">
                                                                <div className="pricingtable-price">
                                                                    <div className="pricingtable-icon"><i className="flaticon-rocket-ship"></i></div>
                                                                    <h4 className="font-weight-300 m-t10 m-b0">Starter</h4>
                                                                    <span className="pricingtable-bx text-primary">$25</span> <span className="pricingtable-type">Par Month</span>
                                                                </div>
                                                                <ul className="pricingtable-features">
                                                                    <li><i className="fa fa-check text-primary"></i> Full Responsive </li>
                                                                    <li><i className="fa fa-check text-primary"></i> Multi color theme</li>
                                                                    <li><i className="fa fa-check text-primary"></i> With Bootstrap</li>
                                                                    <li><i className="fa fa-check text-primary"></i> Easy to customize</li>
                                                                    <li><i className="fa fa-check text-primary"></i> Many Sortcodes</li>
                                                                </ul>
                                                                <div className="m-t20">
                                                                    <Link to={"#"} className="site-button outline outline-2 button-md">Sign Up</Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 col-md-4 col-lg-4 p-lr0">
                                                        <div className="pricingtable-wrapper style1 active">
                                                            <div className="pricingtable-inner">
                                                                <div className="pricingtable-price">
                                                                    <div className="pricingtable-icon"><i className="flaticon-users"></i></div>
                                                                    <h4 className="font-weight-300 m-t10 m-b0">Professional</h4>
                                                                    <span className="pricingtable-bx text-primary">$50</span> <span className="pricingtable-type">Par Month</span>
                                                                </div>
                                                                <ul className="pricingtable-features">
                                                                    <li><i className="fa fa-check text-primary"></i> Full Responsive </li>
                                                                    <li><i className="fa fa-check text-primary"></i> Multi color theme</li>
                                                                    <li><i className="fa fa-check text-primary"></i> With Bootstrap</li>
                                                                    <li><i className="fa fa-check text-primary"></i> Easy to customize</li>
                                                                    <li><i className="fa fa-check text-primary"></i> Many Sortcodes</li>
                                                                </ul>
                                                                <div className="m-t20 m-b5">
                                                                    <Link to={"#"} className="site-button button-md">Sign Up</Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 col-md-4 col-lg-4 p-lr0">
                                                        <div className="pricingtable-wrapper style1">
                                                            <div className="pricingtable-inner">
                                                                <div className="pricingtable-price">
                                                                    <div className="pricingtable-icon"><i className="flaticon-bar-chart"></i></div>
                                                                    <h4 className="font-weight-300 m-t10 m-b0">Enterprise</h4>
                                                                    <span className="pricingtable-bx text-primary">$75</span> <span className="pricingtable-type">Par Month</span>
                                                                </div>
                                                                <ul className="pricingtable-features">
                                                                    <li><i className="fa fa-check text-primary"></i> Full Responsive </li>
                                                                    <li><i className="fa fa-check text-primary"></i> Multi color theme</li>
                                                                    <li><i className="fa fa-check text-primary"></i> With Bootstrap</li>
                                                                    <li><i className="fa fa-check text-primary"></i> Easy to customize</li>
                                                                    <li><i className="fa fa-check text-primary"></i> Many Sortcodes</li>
                                                                </ul>
                                                                <div className="m-t20">
                                                                    <Link to={"#"} className="site-button outline outline-2 button-md">Sign Up</Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                                </div>
                            </div>
                        </div>
                        {/* <!-- Testimonials --> */}
                        <div className="section-full content-inner "   style={{ backgroundImage: "url(" + bgmap + ")", backgroundPosition: "center", backgroundRepeat: "no-repeat" }}>
                            <div className="container">
                                <div className="section-head text-center">
                                    <h2 className="box-title m-tb0">Our Testimonials<span className="bg-primary"></span></h2>
                                    <p> We, at Fletofix, believe in working hand-in-hand with our clients, providing them premium quality inbound and outbound sales and lead generation services that align perfectly with the changing demands. We go out of the way to meet our clients’ requirements, providing them results beyond expectations.</p>
                                </div>
                                <div className="section-content m-b30 ">
									<TestimonialCarousel /> 
                                </div>
                            </div>
                        </div>
                        {/* <!-- Testimonials END --> */}
                        {/* <!-- Get in touch --> */}
                        <div className="section-full overlay-primary-dark bg-img-fix" style={{ backgroundImage: "url(" + bg1 + ")" }}>
                            <FormStyle />
                        </div>
                        {/* <!-- Get in touch --> */}
                    </div>
                    {/* <!-- contact area END --> */}
                </div>

                <Footer />
            </>
        )
    }
}
export default Service;