import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import Header from './../../Layout/Header1';
import Footer from './../../Layout/Footer1';
import PageTitle from './../../Layout/PageTitle';
import SectionCounter from './../../Element/SectionCounter';
import TestimonialCarousel from './../../Element/TestimonialCarousel';
import FormStyle from './../../Element/FormStyle';

//Images
import  about9 from './../../../images/about/pic9.jpg';
import bg2 from './../../../images/background/bg-map.jpg';
import services1 from './../../../images/our-services/pic1.jpg';
import services2 from './../../../images/our-services/pic2.jpg';
import bg1 from './../../../images/background/bg1.jpg';

import bnr from './../../../images/banner/bnr2.jpg';

class Aboutus1 extends Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content bg-white">
					<div className="dlab-bnr-inr overlay-primary" style={{ backgroundImage: "url(" + bnr + ")" }}>
						<PageTitle motherMenu='About Us' activeMenu='About Us' />
					</div>	
                    <div className="content-block">
                        <div className="section-full content-inner-2">
                            <div className="container">
                                <div className="section-head text-black text-center">
                                    <h4 className="text-gray-dark m-b10">About Us</h4>
                                    <h2 className="box-title m-tb0">We create brand new corporate identities<span className="bg-primary"></span></h2>
                                    {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p> */}
                                </div>
                            </div>
                            <div className="container">
                                <div className="row ">
                                    <div className="col-lg-5 col-md-4 about-img">
                                        <img src={about9} data-tilt alt="" />
                                    </div>
                                    <div className="col-lg-7 col-md-8">
                                        <div className="abuot-box left row m-lr0 ">
                                            <div className="col-lg-4">
                                                <h2 className="box-title m-tb0">About Us<span className="bg-primary"></span></h2>
                                                <h4 className="text-gray-dark">We create unique experiences</h4>
                                            </div>
                                            <div className="col-lg-8">
                                                <p>We are a full-service digital media and marketing company. We lead the digitally interactive lives for the always-on world.</p>
                                                <p>Fletofix is a full-service digital media and technology company focused on delivering end-to-end marketing solutions to brands and companies across the globe.</p>
                                                <p>We strive towards digitally interactive lives for the always-on world. Knowing that our audience is exposed to digital, retail, social, mobile and commerce all within a moment, we believe that the best way for brands to have a meaningful impact is to fill all disconnections from our daily lives.</p>
                                                <p className="m-b0">We are a crew of digital enthusiasts who specialize in digital strategy, social media management, content creation, web & mobile development, creative execution, SEO/SEM, media planning, influencer marketing, i-video and all the other digital services the world is yet to see.</p>
                                               
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
						{ /* Counetr section */ }
							<SectionCounter />
						{ /* Counetr section End*/ }
							<VisionBlog />
							
                        {/* <!-- Testimonials --> */}
                        <div className="section-full content-inner" style={{ backgroundImage: "url(" + bg2 + ")", backgroundPosition: "center", backgroundRepeat: "no-repeat" }}>
                            <div className="container">
                                <div className="section-head text-center">
                                    <h2 className="box-title m-tb0">Our Testimonials<span className="bg-primary"></span></h2>
                                    <p> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the </p>
                                </div>
                                <div className="section-content m-b30 ">
                                    <TestimonialCarousel />
                                </div>
                            </div>
                        </div>
                        {/* <!-- Testimonials END --> */}
                        <div className="section-full overlay-primary-dark bg-img-fix" style={{ backgroundImage: "url(" + bg1 + ")" }}>
                            <FormStyle />
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}
function VisionBlog(){
	return(
		<>
			<div className="section-ful our-about-info content-inner-1 " style={{ backgroundImage: "url(" + bg2 + ")",backgroundPosition:"center" ,backgroundRepeat:"no-repeat" }}>
				<div className="container">
					<div className="section-head text-center">
						<h2 className="box-title m-tb0">Mission And Vision<span className="bg-primary"></span></h2>
						<p> Use the comprehensible technology, we fulfill the growing IT needs being felt by consumers who are being touched by it in every aspect of Life. Globalization and the shrinking world on net has made available access to most complex products and solutions. We realize that we will be among them that are competing for your trust. </p>
					</div>
					<div className=" row dzseth  m-b30">
						<div className="col-lg-6 col-md-12 m-b30 about-img ">
							<img src={services1} data-tilt alt="" />
						</div>
						<div className="col-lg-6 col-md-12 m-b30 dis-tbl text-justify">
							<div className="dis-tbl-cell">
								<h3 className="box-title">Mision<span className="bg-primary"></span></h3>
								<p className="font-16">To produce the most effective and smart mobile and web solutions, custom-made for specific goals and objectives of our clients that open new, promising horizons for them. </p>
								<p className="font-16">To help global companies overcome their technology challenges and smoothen their business digital transformation with the emphasis on new and emerging technologies. </p>
								<p className="font-16">To develop an unparalleled user experience and deliver the maximum ROI for our clients by coming up with high-end digital enablement solutions that make a positive impact on all commercial aspects.</p>
                                {/* <p className='font-16'>To provide our valuable Fletofix team with a learning environment that motivates and drives them for absolute perfection and innovation within the best industry practices.</p> */}
                            </div>
						</div>
					</div>
					<div className="row dzseth column-reverse">
						<div className="col-lg-6 col-md-12 dis-tbl text-justify">
							<div className="dis-tbl-cell">
								<h3 className="box-title">Vision<span className="bg-primary"></span></h3>
								<p className="font-16">
                                Creativity, instinctive technical knowledge, and all-things digital run in the blood of Fletofix, and our vision is to establish meaningful interactions and build a rewarding connection between the brands and their audiences by capitalizing every digital user touch-point.
								</p>
								<p className="font-16">	
                                We will make this journey a whole lot easier for you while you work and improve your product and service we will ensure your social media is getting you more recognition and business.
								</p>
								<p className="font-16">
								We believe and strive to always deliver customized business solutions that function perfectly with each client's individual digital ecosystem and business environment.
								</p>
							</div>
						</div>
						<div className="col-lg-6 col-md-12 about-img ">
							<img src={services2} data-tilt alt="" />
						</div>
					</div>
				</div>
			</div>
		</>
	)
}	

export {VisionBlog};
export default Aboutus1;