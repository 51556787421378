import React,{Component} from 'react';			

export default class ThemeButton extends Component{
	render(){
		return(
			<>
				<a href="https://web.whatsapp.com/send?phone=+8801845087715" className="bt-buy-now theme-btn"><i className="fa fa-whatsapp"></i><span>Whatsapp</span></a>
				{/* <a href="https://m.me/fletofix"  className="bt-support-now theme-btn"><i className="fab fa-facebook-messenger"></i><span>Facebook</span></a> */}
				<a href="https://www.facebook.com/fletofix"  className="bt-support-now theme-btn"><i className="ti-facebook"></i><span>Facebook</span></a>
			</>
		)
	}	
}
