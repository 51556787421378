import React,{Fragment, Component} from 'react';
import Axios from 'axios';

class FormStyle extends Component{

	constructor(props) {
        super(props);

        this.state = {
            redirect: false,
            f_name: "",
            email: "",
            phone: "",
            message: "",
            errors: {
                f_name: "",
                email: "",
                phone: "",
                message: "",
            },
            loading: false,
        };
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        const errors = this.state.errors;

        switch (name) {
            case "f_name":
                errors.f_name = value.trim() ? "" : "This field is required.";
                break;
            
            case "email":
                    errors.email = value.trim() ? "" : "This field is required.";
                    break;

            case "phone":
                    errors.phone = value.trim() ? "" : "This field is required.";
                    break;

            case "message":
                    errors.message = value.trim() ? "" : "This field is required.";
                    break;
        
            default:
                break;
        }

        this.setState({ errors, [name]: value});

    };

    sendMail = (event) => {
        event.preventDefault();

            this.setState({ loading: true });

            const { f_name, email, phone, message } = this.state;

            if(f_name !== "" && email !== "" && phone !== "" && message !== "") {
                const postData = new FormData();
                postData.append('f_name', f_name);
                postData.append('email', email);
                postData.append('phone', phone);
                postData.append('message', message);
                
                Axios("https://fletofix.com/mail.php", {
                    method: "POST",
                    data: postData,
                    headers: { "Content-Type": "multipart/form-data" },
                })
                    .then((res) => {
                        alert("Your message has been successfully sent. We will contact you very soon!");

                        this.props.onSearchTermChange(f_name);
                        
                        
                        this.setState({f_name: "", email: "", phone: "", message: ""});
                        console.log(res);
                          

                    
                    })
                    .catch((err) => {
                        console.log("in error");
                        const f_name = "";
                        const email = "";
                        const phone = "";
                        const message = "";
                      
                        this.setState({f_name, email, phone, message});
                        
                    });
            } else {
                alert("All fields are required");
            }        
    };




	render(){
		return(
			<Fragment>
				<div className="container">
					<div className="row">
						<div className="col-lg-5 col-md-5 content-inner chosesus-content text-white">
							<h2 className="box-title m-b15 " >Let's get in touch<span className="bg-primary"></span></h2>
							<p className="font-16 op8" >Give us a call or drop by anytime, we endeavour to answer all enquiries within 24 hours on business days.</p>
							<h3 className="font-weight-500 m-b50 op6 " >Drop us a line right away and we promise to get back to you within one business day!.</h3>
							{/* <h4 className="" >& What you will get:</h4>
							<ul className="list-checked primary " >
								<li><span>Contrary to popular belief, Lorem Ipsum is not simply</span></li>
								<li><span>Random text. It has roots in a piece of classical Latin literature</span></li>
								<li><span>Latin professor at Hampden-Sydney College in Virginia</span></li>
							</ul> */}
						</div>
						<div className="col-lg-7 col-md-7 m-b30">
							<form className="inquiry-form  dzForm"   onSubmit={this.sendMail}>
								<div className="dzFormMsg"></div>
								<h3 className="box-title m-t0 m-b10">Let's Convert Your Idea into Reality <span className="bg-primary"></span></h3>
								<p>Hit that button and we’ll be one step closer to meeting</p>
								
								
								
								<div className="row">
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <span className="input-group-addon"><i className="ti-user text-primary"></i></span>
                                                        <input name="f_name" onChange={this.handleChange} value={this.state.f_name} type="text" required className="form-control" placeholder="Full Name" />
                                                    </div>
                                
                                                        <div className="text-danger">{this.state.errors.f_name}</div>
                                                </div>
                                            </div>
                                            {/* <div className="col-lg-6">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <span className="input-group-addon"><i className="ti-user text-primary"></i></span>
                                                        <input name="l_name" onChange={this.handleChange} type="text" required className="form-control" placeholder="Last Name" />
                                                    </div>
                                                </div>
                                            </div> */}

                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <span className="input-group-addon"><i className="ti-mobile text-primary"></i></span>
                                                        <input name="phone" onChange={this.handleChange} value={this.state.phone} type="text" required className="form-control" placeholder="Phone" />
                                                    </div>
                                                    <div className="text-danger">{this.state.errors.phone}</div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <span className="input-group-addon"><i className="ti-email text-primary"></i></span>
                                                        <input name="email" type="email" onChange={this.handleChange} value={this.state.email} className="form-control" required placeholder="Your Email Id" />
                                                    </div>
                                                    <div className="text-danger">{this.state.errors.email}</div>
                                                </div>
                                            </div>


                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <span className="input-group-addon"><i className="ti-agenda text-primary"></i></span>
                                                        <textarea name="message" onChange={this.handleChange} value={this.state.message} rows="4" className="form-control" required placeholder="Tell us about your project or idea"></textarea>
                                                    </div>
                                                    <div className="text-danger">{this.state.errors.message}</div>
                                                </div>
                                            </div>
                                            
                                            <div className="col-lg-12">
                                                <button type="submit"   className="button-lg site-button"> <span>Get A Free Quote!</span> </button>
                                            </div>
                                        </div>
							</form>
						</div>
					</div>
				</div>
			</Fragment>
		)
	}
}
export default FormStyle;