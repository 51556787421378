import React, {Component,useState,useEffect,Fragment } from 'react';
import { Link } from 'react-router-dom';
import SimpleReactLightbox from 'simple-react-lightbox';
import {SRLWrapper, useLightbox} from 'simple-react-lightbox'; 
import Header from './../../Layout/Header1';
import Footer from './../../Layout/Footer1';
import PageTitle from './../../Layout/PageTitle';
import Masonry from 'react-masonry-component';

//images
import bnr1 from './../../../images/banner/bnr1.jpg';
// import box1 from './../../../images/portfolio/image_1.jpg';
// import box2 from './../../../images/portfolio/image_2.jpg';
// import box3 from './../../../images/portfolio/image_3.jpg';
// import box4 from './../../../images/portfolio/image_4.jpg';
// import box5 from './../../../images/portfolio/image_5.jpg';
// import box6 from './../../../images/portfolio/image_6.jpg';
// import box7 from './../../../images/portfolio/image_7.jpg';
// import box8 from './../../../images/portfolio/image_8.jpg';
// import box9 from './../../../images/portfolio/image_9.jpg';
// import box10 from './../../../images/portfolio/image_10.jpg';
// import box11 from './../../../images/portfolio/image_11.jpg';
// import box12 from './../../../images/portfolio/image_12.jpg';
import box1 from './../../../images/portfolio/website/w1.jpg';
import box2 from './../../../images/portfolio/website/w2.jpg';
import box3 from './../../../images/portfolio/website/w3.jpg';
import box4 from './../../../images/portfolio/website/w4.jpg';
import box5 from './../../../images/portfolio/uiux/u1.jpg';
import box6 from './../../../images/portfolio/uiux/u2.jpg';
import box7 from './../../../images/portfolio/uiux/u3.jpg';
import box8 from './../../../images/portfolio/uiux/u4.jpg';

import box9 from './../../../images/portfolio/product/p1.jpg';
import box10 from './../../../images/portfolio/product/p2.jpg';
import box11 from './../../../images/portfolio/product/p3.jpg';
import box12 from './../../../images/portfolio/product/p3.jpg';

import box13 from './../../../images/portfolio/printmedia/pm1.jpg';
import box14 from './../../../images/portfolio/printmedia/pm2.jpg';

import box15 from './../../../images/portfolio/digitalmedia/d1.jpg';
import box16 from './../../../images/portfolio/digitalmedia/d2.jpg';
import box17 from './../../../images/portfolio/digitalmedia/d3.jpg';
import box18 from './../../../images/portfolio/digitalmedia/d4.jpg';
import box19 from './../../../images/portfolio/digitalmedia/d5.jpg';
import box20 from './../../../images/portfolio/digitalmedia/dl1.jpg';
import box21 from './../../../images/portfolio/digitalmedia/dl2.jpg';
import box22 from './../../../images/portfolio/digitalmedia/dl3.jpg';
import box23 from './../../../images/portfolio/digitalmedia/dl4.jpg';


import box24 from './../../../images/portfolio/social/s1.jpg';
import box25 from './../../../images/portfolio/social/s2.jpg';
import box26 from './../../../images/portfolio/social/s3.jpg';
import box27 from './../../../images/portfolio/social/s4.jpg';
import box28 from './../../../images/portfolio/social/s5.jpg';
import box29 from './../../../images/portfolio/social/s6.jpg';







const imageBlog = [
	{  Large: box1 , tag: ['Website'] , name: 'E-learning' },
	{  Large: box2 , tag: ['Website'] , name: 'Fitness Center'  },
	{  Large: box3 , tag: ['Website'] , name: 'Diagnostic Center' }, 
	{  Large: box4 , tag: ['Website'] , name: 'Production House' },
	
	{  Large: box5 , tag: ['UI/UX'],   name: 'Restaurant Website' }, 
	{  Large: box6 , tag: ['UI/UX'],   name: 'E-commerce App' }, 
	{  Large: box7 , tag: ['UI/UX'],   name: 'Hotel Website' }, 
	{  Large: box8 , tag: ['UI/UX'],   name: 'Signin Signup' },  
	
	{  Large: box9 , tag: ['Product'] , name: 'Diagnostic Management System'  }, 
	{  Large: box10 , tag: ['Product'] ,  name: 'Inventory Management System'}, 
	{  Large: box11 , tag: ['Product'] ,  name: 'Point of Sale'  }, 
	// 12 missing

	{  Large: box13 , tag: ['Print Media'] ,  name: 'Business Card' },
	{  Large: box14 , tag: ['Print Media'] ,  name: 'Thank You Card' },

	{  Large: box15 , tag: ['Digital Media'] , name: 'Digital Post'  }, 
	{  Large: box16 , tag: ['Digital Media'] ,  name: 'Digital Post' },
	{  Large: box17 , tag: ['Digital Media'] ,  name: 'Digital Post' },
	{  Large: box18 , tag: ['Digital Media'] ,  name: 'Digital Post' }, 
	{  Large: box19 , tag: ['Digital Media'] ,  name: 'Digital Post' },
	{  Large: box22 , tag: ['Digital Media'] ,  name: 'Logo' },
	{  Large: box20 , tag: ['Digital Media'] ,  name: 'Logo' },
	{  Large: box21 , tag: ['Digital Media'] ,  name: 'Logo' },	
	{  Large: box23 , tag: ['Digital Media'] ,  name: 'Logo' },	
	
	{  Large: box24 , tag: ['Social Media Campaign'] , name: 'Facebook'}, 
	{  Large: box25 , tag: ['Social Media Campaign'] , name: 'Facebook' },
	{  Large: box26 , tag: ['Social Media Campaign'] , name: 'Facebook' },
	{  Large: box27 , tag: ['Social Media Campaign'] , name: 'Facebook' },
	{  Large: box28 , tag: ['Social Media Campaign'] , name: 'Facebook' },
	{  Large: box29 , tag: ['Social Media Campaign'] , name: 'Facebook' },	


]


//Light Gallery on icon click 
const Iconimage = props => {
	const { openLightbox } = useLightbox()
	return (
    <Link  to={"#"} onClick={() => openLightbox(props.imageToOpen)} className="mfp-link portfolio-fullscreen" >
      <i className="ti-fullscreen icon-bx-xs"></i>
    </Link>
  )
}

// Masonry section
const masonryOptions = {                                          
    transitionDuration: 0
};

const imagesLoadedOptions = { background: '.my-bg-image-el' }
// Masonry section end

const TagLi = ({name, handlesettag, tagActive}) => {                                                               
	return ( 
			
		<li className={` tag ${tagActive ? 'btn active' : 'btn'}` } onClick={() => handlesettag(name)} >
			<input type="radio" />
			<Link to={"#"} className="site-button-secondry radius-sm" ><span>{name} {''}</span> </Link>
		</li> 
	);
};


class ProtfolioFullWidth extends Component{
	render(){
		return(
			<Fragment>	
			<Header />
			<div className="page-content bg-white">
				{/*  banner  */}
				<div className="dlab-bnr-inr dlab-bnr-inr-sm overlay-primary bg-pt" style={{ backgroundImage: "url(" + bnr1 + ")" }}>
					<PageTitle motherMenu="Our Awesome Projects"  activeMenu="Work"  />
				</div>
				{/*  Section-1 Start  */}
				<PortfolioItem />
				
			</div>	
			<Footer />
			</Fragment>			
		)
	}
} 
function PortfolioItem(){
	const [tag, setTag] = useState('All');
	const [filteredImages, setFilterdImages] = useState([]);
	
	useEffect( () => {
		tag === 'All' ? setFilterdImages(imageBlog) : setFilterdImages(imageBlog.filter( image => image.tag.find(element => element === tag)))
	}, [tag])	
	
	return(
		<>
			<div className="section-full content-inner-1 portfolio text-uppercase">		
				<div className="site-filters clearfix center  m-b40">
					
					<ul className="filters" data-toggle="buttons">
						<TagLi name="All"  handlesettag={setTag} tagActive={ tag === 'All' ? true : false }	/>
						<TagLi name="Website"  handlesettag={setTag} tagActive={ tag === 'Website' ? true : false }	/>
						<TagLi name="UI/UX" handlesettag={setTag} tagActive={ tag === 'UI/UX' ? true : false }	 />
						<TagLi name="Product"  handlesettag={setTag} tagActive={ tag === 'Product' ? true : false }	/>
						<TagLi name="Social Media Campaign"  handlesettag={setTag} tagActive={ tag === 'Social Media Campaign' ? true : false }	/>
						<TagLi name="Digital Media"  handlesettag={setTag} tagActive={ tag === 'Digital Media' ? true : false }	/>
						<TagLi name="Print Media"  handlesettag={setTag} tagActive={ tag === 'Print Media' ? true : false }	/>
						
						{/* <TagLi name="Marketing"  handlesettag={setTag} tagActive={ tag === 'Marketing' ? true : false }	/> */}

						{/* <TagLi name="Video"  handlesettag={setTag} tagActive={ tag === 'Video' ? true : false }	/> */}
						{/* <TagLi name="Photography"  handlesettag={setTag} tagActive={ tag === 'Photography' ? true : false }	/> */}
						{/* <TagLi name="Videography"  handlesettag={setTag} tagActive={ tag === 'Videography' ? true : false }	/> */}
					</ul>
					
				</div>
				<SimpleReactLightbox>
					<SRLWrapper >
						<div className="clearfix">
							<ul id="masonry" className="dlab-gallery-listing gallery-grid-4 gallery mfp-gallery port-style1">
								<Masonry
									className={'my-gallery-class'} // default ''
									options={masonryOptions} // default {}
									disableImagesLoaded={false} // default false
									updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
									imagesLoadedOptions={imagesLoadedOptions} // default {}
								>
									{filteredImages.map((item, index)=>(
										<li className="web design card-container col-lg-3 col-md-6 col-xs-12 col-sm-6 p-a0" key={index}>
											<div className="dlab-box dlab-gallery-box">
												<div className="dlab-media dlab-img-overlay1 dlab-img-effect"> 
													<img src={item.Large} alt="" />
													<div className="overlay-bx">
														<div className="overlay-icon align-b text-white text-left">
															<div className="text-white text-left port-box">
																<h5>{item.name ? item.name : ""}</h5>
																<p>{item.tag ? item.tag : ""}</p>
																<Iconimage />
															</div>
														</div>
													</div>
												</div>
											</div>
										</li>
									))}	
								</Masonry>	
							</ul>
						</div>
					</SRLWrapper>
				</SimpleReactLightbox>	
				
			</div>
		</>
	);
}
export {box1, box2, box3, box4, box5, box6, box7, box8, box9, box10, box11, box12, box13, box14, box15, box16, box17, box18, box19, box20, box21,
	 box22, box23, box24, box25, box26, box27, box28, box29,
	imageBlog};
export default ProtfolioFullWidth;
