import React,{Component} from 'react';
import {Link} from 'react-router-dom';

class HeaderMenu extends Component{
	render(){
		return(
			<>
				<ul className="nav navbar-nav">
					<li className="active"><Link to={'/'}><span className="ti-home"></span> <i className="fa"></i></Link>
					</li>
					
					

					<li><Link to={'/about'}>Who We Are <i className="fa "></i></Link></li>
				
				
					
					{/* <li><Link to={'./our-projects'} className="dez-page">Our Projects</Link></li> */}
					{/* <li><Link to={'./team'} className="dez-page">Our Team</Link></li> */}

					<li><Link to={'/service'}>What We Do <i className="fa"></i></Link></li>
					<li><Link to={'/portfolio'}>Work <i className="fa"></i></Link></li>
					<li><Link to={'/project-management'} className="dez-page">How We Manage</Link></li>
					{/* <li><Link to={'./'}>Blog <i className="fa"></i></Link></li> */}
					<li><Link to={'/contact'}>Contact Us <i className="fa"></i></Link></li>
	
				</ul>
			</>
		)
	}
}
export default HeaderMenu;